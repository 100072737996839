<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col>
            <b-form-group
              label="Başlangıç Tarihi"
              label-for="sdate"
            >
              <b-form-datepicker
                id="sdate"
                v-model="sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Bitiş Tarihi"
              label-for="edate"
            >
              <b-form-datepicker
                id="edate"
                v-model="edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="text-center"
          >
            <b-button
              variant="danger"
              :disabled="!sdate || !edate"
              :href="downloadURL"
              target="_blank"
            >
              <FeatherIcon icon="BarChartIcon" />
              <span class="align-middle">Excel Aktar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BFormDatepicker, BButton, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'Accessories',
  components: {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      sdate: null,
      edate: null,
    }
  },
  computed: {
    downloadURL() {
      const urlParams = [
        `&sdate=${this.sdate}`,
        `&edate=${this.edate}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/reports/sales/accessories/excel?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
  },
}
</script>

<style scoped>

</style>
